<template>
    <v-row class="mx-2" align="center" justify="center">  
        عرض 
        {{ $global.state.filter.pageSize > $global.state.count ? $global.state.count : $global.state.filter.pageSize }}
        من اصل
        {{ $global.state.count }}
        <v-spacer/>
        <v-pagination @change="getItems()" @input="getItems()" total-visible="7" color="primary" class="py-4"  v-model="$global.state.filter.pageIndex" :length="$global.state.length" />
    </v-row>
</template>

<script>
export default {
    created() {
        this.$global.state.filter.pageIndex = 1
    },
    methods: {
        getItems(){
            this.$eventBus.$emit(`${this.$route.path}`)
        }
    },
}
</script>